import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const WaddlePage = () => (
    <Layout>
        <Seo 
            title="Gatsby Contentful case study" 
            description="Case study of the Gatsby and Contentful web development headless CMS project completed by Launch Lab for Waddle, a Xero company"
        />

        <Hero
            h1="Website and Contentful CMS case study"  
            h2="Waddle, a Xero company"
        />

        
        <section className="bg-noise waddle section-pad-sm">
            <div className="container-lg" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/waddle-main.png"
                        alt="Screens of the Waddle website"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>

        </section>
        
        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#testimonial')} className="btn">
                                        Testimonial
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Waddle is an Australian financial technology startup that was acquired by Xero. To fuel their growth, a new marketing website and content management system was required.</p>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h3>The work</h3>
                                    <p>Launch Lab developed the new Waddle website using the popular Gatsby static site generator library.</p>
                                    <p>Contentful was used as the CMS making content easy to manage and providing an easy-to-use CMS interface.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/gatsby-developer/" className="btn btn-lg btn-line">Gatsby.js</Link>
                                    <Link to="/web-development/" className="btn btn-lg btn-line">Web development</Link>
                                    <Link to="/headless-cms/" className="btn btn-lg btn-line">Contentful CMS integration</Link>
                                    <Link to="/web-development/" className="btn btn-lg btn-line">API integration</Link>
                                    <Link to="/aws-infrastructure/" className="btn btn-lg btn-line">AWS</Link>
                                </div>
                                <div id="testimonial" className="porti-text-section">
                                    <h4>Testimonial</h4>
                                    <Testimonial
                                        quote="I worked with Launch Lab on the development of my company's website. The entire experience has been exceptional and I'd be really keen to work with them again."
                                        name="Russ Watts"
                                        title="Head of Marketing, Waddle (a Xero company)"
                                    />
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the new Waddle website.</p>
                                    <a href="https://waddle.com.au/" className="btn btn-lg btn-purple">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            title="Start a project"
            text="Get an obligation free web design or web development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default WaddlePage